/* eslint-disable */
console.info("process.env.NODE_ENV", process.env.NODE_ENV);
module.exports = {
    'appName': 'Si Gadis Pintar',
    'versi': '0.2.1 Beta',
    // 'apiUrl': (process.env.NODE_ENV !== 'production') ? (`${window.location.protocol}//192.168.18.21:1083`) : (`https://sigadispintar.smp5mojokerto.sch.id/api`),
    'apiUrl': `https://sigadispintar.smp5mojokerto.sch.id/api`,
    // 'apiUrl': `http://lokal.com:1083`,
    'perpage': 30,
    'key': `fac6bf92dd997c72f16ad1b6bffa9bef`,
    'progam': '001'
};