<template>
	<div :id="'modal' + name" class="w3-modal">
		<div class="w3-modal-content w3-animate-zoom w3-card-8 w3-theme-d5 w3-round-large" :id="'modalBody' + name"
			:style="'width:' + width + ';position: absolute;top: ' + top + ';'">
			<header class="w3-container w3-round-large" style="cursor:move;" :id="'modalBody' + name + 'header'">
				<span @click="eventCloseModal" class="w3-display-topright w3-large w3-button w3-hover-red w3-round"
					style="background-color: inherit; cursor: pointer; margin: 5px 10px 0 0; width: 30px; height: 30px; padding: 1px;">&times;</span>
				<h6><i class="fa" :class="icon"></i> {{ title }}</h6>
			</header>
			<div class="w3-container w3-theme-l4 de-box w3-round-large"
				:style="!showButton ? 'margin:0px 10px 10px 10px;' : 'margin:0px 10px 0px 10px;'">
				<slot name="modal-body"></slot>
			</div>
			<footer class="w3-container" v-if="showButton">
				<slot name="footer-left"></slot>
				<h6 class="w3-right">
					<slot name="footer-right">
						<button v-if="statusSubmit" :form="'form' + name" type="submit" class="w3-btn de-btn de-blue w3-small"
							style="margin-right:5px;"><i class="fa fa-save"></i> Save</button>
						<button type="button" @click="buttonClick($event, eventCloseModal)"
							class="w3-btn w3-red w3-small de-btn de-red"><i class="fa fa-close"></i> Close</button>
					</slot>
				</h6>
			</footer>
			<footer v-else>
				<slot name="footer"></slot>
			</footer>
		</div>
	</div>
</template>
<script>
/**
 * Cara Penggunaan
 * <DeModal :name="'Gudang'" :width="'500px'" :top="'50px'" :title="Add / Edit data" :icon="fa-windows" >
		<template #modal-body>
			<form method="post" id="formGudang" v-on:submit.prevent="saveData" action="#" autocomplete="off">
			</form>
		</template>
		<template #footer-left>
			<label class="de-container w3-small w3-left" style="margin-top: 15px;">Auto add
				<input true-value="T" false-value="F" v-model="gudang.pages.autoadd" type="checkbox" checked="checked">
				<span class="checkmark"></span>
			</label>
		</template>
	</DeModal>
 * 
 */
export default {
	"props": {
		"name": {
			"type": String,
			"required": true,
			"default": "Modal"
		},
		"width": {
			"type": String,
			"default": "500px"
		},
		"top": {
			"type": String,
			"default": "50px"
		},
		"title": {
			"type": String,
			"default": "Add / Edit data"
		},
		"icon": {
			"type": String,
			"default": "fa-windows"
		},
		"showButton": {
			"type": Boolean,
			"default": true
		},
		"statusSubmit": {
			"type": Boolean,
			"default": true
		},
		"onClose": {
			type: Function,
			required: false,
			default: () => { }
		}
	},
	methods: {
		eventCloseModal() {
			console.info('this.onClose', this.onClose);
			if (this.onClose != undefined && typeof this.onClose === 'function') {
				this.onClose();
			}
			this.closeModal(this.name);
		}
	},
	created: function () {
	},
	mounted() {
		try {
			console.info("Proses Load" + this.name);
			this.dragElement(document.getElementById("modalBody" + this.name));
		} catch (error) {
			console.info(error);
		}
	}
}
</script>