/* eslint-disable */
import { createRouter, createWebHashHistory } from 'vue-router'
// import Config from '@/conf/Config.js'

let routes = [
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    },
    {
        path: '/404',
        component: () => import("@/views/public/NoPage.vue"),
        name: "404",
        meta: {
            auth: false,
            title: "Not Found",
            icon: "fa fa-magic",
            closable: true
        }
    },
    // Untuk admin
    {
        path: "/login",
        name: "AdminLogin",
        component: () => import("@/views/public/AdminLogin.vue"),
        meta: {
            auth: false,
            title: 'AdminLogin'
        },
    },
    {
        path: "/home",
        name: "Home",
        component: () => import("@/views/public/Home.vue"),
        meta: {
            auth: false,
            title: 'Home'
        },
    },
    // Untuk admin
    {
        path: "/",
        name: "Main",
        component: () => import('@/views/admin/Main.vue'),
        meta: {
            auth: true,
            title: 'Dashboard',
            tips: 'Dashboard',
            icon: 'fa fa-home',
            closable: false,
            tabClass: 'w3-theme-l1',
        },
        children: [
            {
                path: '*',
                component: () => import("@/views/public/NoPage.vue"),
                name: "NoPageAnak",
                meta: {
                    auth: false,
                    title: "Not Found",
                    icon: "fa fa-magic",
                    closable: true
                }
            },
        ]
    }
];

import mixin from '../mixin.js';

async function setMenuAwal() {
    if (localStorage.adminSgp != undefined) {
        const menusdetail = JSON.parse(mixin.methods.DeCompresString(localStorage["SgpDbMenus"]));
        for (let index = 0; index < menusdetail.length; index++) {
            let element = menusdetail[index];
            routes[4].children.push(
                {
                    path: element["path"],
                    name: element["name"],
                    urlimport: element["urlimport"],
                    component: () => import(`@/${element["urlimport"]}`),
                    meta: element["meta"]
                }
            );
        }
    }
}

setMenuAwal();


console.info('routes 2', routes[4]);

const router = createRouter({
    history: createWebHashHistory(), //createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    try {
        if (to.name == null) {
            next("/home");
        }
        if (to.matched.some(record => record.meta.auth)) {
            console.info("record");
            vm.checkLogin(function (res) {
                if (res.success == true) {
                    // const login = vm.DeCompresString(localStorage.adminSgp);
                    // console.log('login', login, to.fullPath);
                    if (to.fullPath == "/") {
                        next("/home");
                    } else {
                        next();
                    }
                    // }
                } else {
                    next("/home");
                }
            }, function (err) {
                next("/home");
            });
        } else {
            next();
        }
    } catch (error) {
        next("/home");
        console.info('router error1', error);
    }
});

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        // NProgress.start();
        // this.showLoading("Loading ..");
        document.getElementById('txt-loading').innerHTML = "Loading ..";
        document.getElementById('idLoading').style.display = 'block';
    }
    next()
});


router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    // NProgress.done()
    document.getElementById('idLoading').style.display = 'none';
});

export default router
