<template>
    <router-view/>
</template>

<script>
export default {
    name: 'App',
    components: {},
    data() {
        return {
            menuinduk : localStorage.menuinduk
        };
    },
    methods : {
        setMenuInduk(){
            if(this.$route.params.level===0){
                this.menuinduk = this.$route.params.id;
                localStorage.menuinduk = this.menuinduk;
            }
            if(this.$route.params.id === undefined || this.$route.params.id === 'undefined'){
                this.menuinduk = -1;
                localStorage.menuinduk = this.menuinduk;
            }
        }
    },
    watch: {
        '$route': 'setMenuInduk'
    }
}
</script>
