<template>
  <label class="container-switch">
    <input
      v-bind="$attrs"
      class="input-switch"
      type="checkbox"
      :checked="checked"
      true-value="T" false-value="F"
      @change="changePageTitle($event.target.checked)"
    />
    <span class="switch"></span>
    <span class="label-switch w3-small">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: "SwitchButton",
  props: {
    label: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    changePageTitle(val){
      this.$emit('update:modelValue', val ? "T" : "F");
    }
  }
};
</script>

<style scoped>
.container-switch {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.label-switch {
  margin-left: 10px;
  color: inherit;
  /* Show an ellipsis if the text takes more than one line */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* Visually hide the checkbox input */
.input-switch {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.switch {
  --switch-container-width: 50px;
  --switch-size: calc(var(--switch-container-width) / 2);
  --light-gray: #e2e8f0;
  --gray: #cbd5e0;
  --orange: #e99a6c;
  --dark-gray: #a0aec0;
  --teal: #0479cc;
  --dark-teal: #034f84;
  /* Vertically center the inner circle */
  display: flex;
  align-items: center;
  position: relative;
  height: var(--switch-size);
  flex-basis: var(--switch-container-width);
  /* Make the container element rounded */
  border-radius: var(--switch-size);
  background-color: var(--orange);
  /* In case the label gets really long, the toggle shouldn't shrink. */
  flex-shrink: 0;
  transition: background-color 0.25s ease-in-out;
}
.switch::before {
  content: "";
  position: absolute;
  /* Move a little bit the inner circle to the right */
  left: 1px;
  height: calc(var(--switch-size) - 4px);
  width: calc(var(--switch-size) - 4px);
  /* Make the inner circle fully rounded */
  border-radius: 9999px;
  background-color: white;
  border: 2px solid var(--light-gray);
  transition: transform 0.375s ease-in-out;
}
.input-switch:checked + .switch {
  background-color: var(--teal);
}
.input-switch:checked + .switch::before {
  border-color: var(--teal);
  /* Move the inner circle to the right */
  transform: translateX(
    calc(var(--switch-container-width) - var(--switch-size))
  );
}
.input-switch:focus + .switch::before {
  border-color: var(--gray);
}
.input-switch:focus:checked + .switch::before {
  border-color: var(--dark-teal);
}
.input-switch:disabled + .switch {
  background-color: var(--gray);
}
.input-switch:disabled + .switch::before {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
}
</style>