// import './registerServiceWorker'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import App from './App.vue'
import mixin from './mixin.js';

// Untuk Form
// import {ModelListSelect} from '@/components/vue-select';
import money from 'v-money3'
// import VueNumberFormat from 'vue-number-format';
import Paging from "@/components/Paging.vue";
import Toolbar from "@/components/Toolbar.vue";
import DeLoading from "@/components/DeLoading.vue";
import DeModal from "@/components/DeModal.vue";
import VueScrollingTable from '@/components/tablescroll/VueScrollingTable.vue';
// eslint-disable-next-line no-undef
require("@/assets/css/style-admin.css");

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import VueMultiselect from '@/components/vue-multiselect'
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

/* eslint-disable */
/** sweet alert */
import swal from "@/assets/swal/sweet-alert.min.js";
require("@/assets/swal/sweet-alert.css");
require("./assets/css/report.css");
require("@/assets/swal/theme.css");
import moment from 'moment';
// import RouterTab from "@/components/routerTab";
// import Vue3TouchEvents from "vue3-touch-events";

import SwitchButton from '@/components/switch-button/Button';

const app = createApp(App);
app.config.productionTip = false;
app.mixin(mixin);
app.use(LottieAnimation);
app.component("VueMultiselect", VueMultiselect);
app.component("Paging", Paging);
app.component("Toolbar", Toolbar);
app.component("DeLoading", DeLoading);
app.component("DeModal", DeModal);
app.component("VueScrollingTable", VueScrollingTable);
app.component("SwitchButton", SwitchButton);
app.use(money);
app.use(SwitchButton);
// app.use(Vue3TouchEvents);
// app.use(RouterTab);
app.use(Toast, {
    position: "top-center",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
});

app.use(router);
app.use(createPinia());

let vm = app.mount('#app');
global.vm = vm;
