<template>
    <div :class="containerClass" >
        <a class="w3-button" v-if="firstLastButton" :class="[pageClass, firstPageSelected() ? disabledClass : '']" @click="selectFirstPage()" @keyup.enter="selectFirstPage()" :tabindex="firstPageSelected() ? -1 : 0" v-html="firstButtonText"></a>
        <a class="w3-button w3-text-gray" v-if="!(firstPageSelected() && hidePrevNext)" :class="[prevClass, firstPageSelected() ? disabledClass : '']" @click="prevPage()" @keyup.enter="prevPage()" :tabindex="firstPageSelected() ? -1 : 0" v-html="prevText"></a>
        <span v-for="page in pages" :class="[pageClass, page.selected ? activeClass : '', page.disabled ? disabledClass : '', page.breakView ? breakViewClass: '']" :key="page.index">
            <a class="w3-button w3-text-gray" v-if="page.breakView" :class="[pageLinkClass, breakViewLinkClass]" tabindex="0"><slot name="breakViewContent">{{ breakViewText }}</slot></a>

            <a class="w3-button w3-text-gray" v-else-if="page.disabled" :class="pageLinkClass" tabindex="0">{{ page.content }}</a>

            <a class="w3-button w3-text-gray" v-else @click="handlePageSelected(page.index + 1)" @keyup.enter="handlePageSelected(page.index + 1)" :class="pageLinkClass" tabindex="0" >{{ page.content }}</a>
        </span>
        <a class="w3-button w3-text-gray" v-if="!(lastPageSelected() && hidePrevNext)" :class="[nextClass, lastPageSelected() ? disabledClass : '']" @click="nextPage()" @keyup.enter="nextPage()" :tabindex="lastPageSelected() ? -1 : 0" v-html="nextText"></a>
        <a class="w3-button" v-if="firstLastButton" :class="[pageClass, lastPageSelected() ? disabledClass : '']" @click="selectLastPage()" @keyup.enter="selectLastPage()" :tabindex="lastPageSelected() ? -1 : 0" v-html="lastButtonText"></a>
    </div>
        
</template>

<script>
/* eslint-disable */
export default {
    name:"DePagging",
    props: {
        value: {
            type: Number,
            default: 1
        },
        pageCount: {
            type: Number,
            required: true
        },
        forcePage: {
            type: Number
        },
        clickHandler: {
            type: Function,
            default: () => { }
        },
        pageRange: {
            type: Number,
            default: 3
        },
        marginPages: {
            type: Number,
            default: 1
        },
        prevText: {
            type: String,
            default: "&#xf104;"
        },
        nextText: {
            type: String,
            default: "&#xf105;"
        },
        breakViewText: {
            type: String,
            default: "…"
        },
        containerClass: {
            type: String
        },
        pageClass: {
            type: String
        },
        pageLinkClass: {
            type: String
        },
        prevClass: {
            type: String
        },
        prevLinkClass: {
            type: String
        },
        nextClass: {
            type: String
        },
        nextLinkClass: {
            type: String
        },
        breakViewClass: {
            type: String
        },
        breakViewLinkClass: {
            type: String
        },
        activeClass: {
            type: String,
            default: "w3-theme-l1"
        },
        disabledClass: {
            type: String,
            default: ""
        },
        noLiSurround: {
            type: Boolean,
            default: false
        },
        firstLastButton: {
            type: Boolean,
            default: false
        },
        firstButtonText: {
            type: String,
            default: "&laquo;"
        },
        lastButtonText: {
            type: String,
            default: "&raquo;"
        },
        hidePrevNext: {
            type: Boolean,
            default: false
        }
    },
    beforeUpdate() {
        if (this.forcePage === undefined) return;
        if (this.forcePage !== this.selected) {
            this.selected = this.forcePage;
        }
    },
    computed: {
        selected: function() {
            return this.value;
        },
        pages: function () {
            let items = {};
            if (this.pageCount <= this.pageRange) {
                for (let index = 0; index < this.pageCount; index++) {
                    let page = {
                        index: index,
                        content: index + 1,
                        selected: index === (this.selected - 1)
                    };
                    items[index] = page;
                }
            } else {
                const halfPageRange = Math.floor(this.pageRange / 2);
                let setPageItem = index => {
                    let page = {
                        index: index,
                        content: index + 1,
                        selected: index === (this.selected - 1)
                    };
                    items[index] = page;
                };
                let setBreakView = index => {
                    let breakView = {
                        disabled: true,
                        breakView: true
                    };
                    items[index] = breakView;
                };
                // 1st - loop thru low end of margin pages
                for (let i = 0; i < this.marginPages; i++) {
                    setPageItem(i);
                }
                // 2nd - loop thru selected range
                let selectedRangeLow = 0;
                if (this.selected - halfPageRange > 0) {
                    selectedRangeLow = this.selected - 1 - halfPageRange;
                }
                let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
                if (selectedRangeHigh >= this.pageCount) {
                    selectedRangeHigh = this.pageCount - 1;
                    selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
                }
                for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
                    setPageItem(i);
                }
                // Check if there is breakView in the left of selected range
                if (selectedRangeLow > this.marginPages) {
                    setBreakView(selectedRangeLow - 1);
                }
                // Check if there is breakView in the right of selected range
                if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
                    setBreakView(selectedRangeHigh + 1);
                }
                // 3rd - loop thru high end of margin pages
                for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--) {
                    setPageItem(i);
                }
            }
            
            return items;
        }
    },
    methods: {
        handlePageSelected(selected) {
            if (this.selected === selected) return;
            // this.value = selected;
            this.$emit("input", selected);
            this.clickHandler(selected);
        },
        prevPage() {
            if (this.selected <= 1) return;
            this.$emit("input", this.selected - 1);
            this.clickHandler(this.selected - 1);
        },
        nextPage() {
            if (this.selected >= this.pageCount) return;
            this.$emit("input", this.selected + 1);
            this.clickHandler(this.selected + 1);
        },
        firstPageSelected() {
            return this.selected === 1;
        },
        lastPageSelected() {
            return (this.selected === this.pageCount) || (this.pageCount === 0);
        },
        selectFirstPage() {
            if (this.selected <= 1) return;
            this.$emit("input", 1);
            this.clickHandler(1);
        },
        selectLastPage() {
            if (this.selected >= this.pageCount) return;
            this.$emit("input", this.pageCount);
            this.clickHandler(this.pageCount);
        }
    }
};
</script>
<style lang="css" scoped>
a {
    cursor: pointer;
}
</style>